import request from '@index/utils/request'

export function handleCreateInvitationCode(data) { //创建邀请码
  return request({
    url: 'matchinvitation/createInvitationCode',
    method: 'post',
    data
  })
}


export function handleDeletInvitationCode(id) { //删除邀请码
  return request({
    url: 'matchinvitation/delete',
    method: 'post',
    params: {
      id: id
    }
  })
}



export function handleQueryMatchInvitationCodes(matchId) { //查询赛事邀请码
  return request({
    url: 'matchinvitation/queryMatchInvitationCodes',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}

export function handleAddManager(data) { //添加授权管理员
  return request({
    url: 'matchinfomanager/addManager',
    method: 'post',
    data
  })
}

export function handleDeleteManager(id) { //删除授权管理员
  return request({
    url: 'matchinfomanager/deleteManager',
    method: 'post',
    params: {
      id: id
    }
  })
}

export function handleQueryManagersOfMatch(matchId) { //查询管理员
  return request({
    url: 'matchinfomanager/queryManagersOfMatch',
    method: 'post',
    params: {
      matchId: matchId
    }
  })
}

export function handleGetSms(phone) {//获取验证码
  return request({
    url: 'assist/out/getValidateCode',
    method: 'post',
    params: {
      phone: phone
    }
  })
}


export function handleSaveMatchRelateSet(data) {//保存比赛设置信息
  return request({
    url: 'matchrelateset/saveMatchRelateSet',
    method: 'post',
    data
  })
}


export function handleQueryMatchRelateSet(matchId) {//查询赛事相关设置
  return request({
    url: 'matchrelateset/queryMatchRelateSet',
    method: 'post',
    params:{matchId:matchId}
  })
}


export function handlepcPayFee(data) {//查询赛事相关设置
  return request({
    url: 'pay/pcPayFee',
    method: 'post',
 responseType: "arraybuffer",  
    data
  })
}



export function handleModifyMatchState(data) {//修改赛事状态
  return request({
    url: 'matchinfo/modifyMatchState',
    method: 'post', 
    data
  })
}

export function handleSetMatchFaceImgUrl(data) { //设置比赛封面
  return request({
    url: 'matchinfo/setMatchFaceImgUrl',
    method: 'post',
    data
  })
}


export function handleSaveMatchCertStatus(data) { //保存电子证书状态
  return request({
    url: 'matchrelateset/saveMatchCertStatus',
    method: 'post',
    data
  })
}


export function handleClearMatchfaceImg(data) { //清除比赛封面
  return request({
    url: 'matchinfo/clearMatchfaceImg',
    method: 'post',
    data
  })
}
export function handleGenerMatchDetailQRcode(data) { //生成比赛详情二维码
  return request({
    url: 'matchinfo/generMatchDetailQRcode',
    method: 'post',
    data
  })
}

//查询赛事管理员的权限  2025-01-21
export function handleQueryMatchManagerAuth(matchId) { 
  return request({
    url: 'matchinfomanager/queryMatchManagerAuth',
    method: 'post',
    params:{matchId:matchId}
  })
}


//查询赛事对应管理员详情  2025-02-24
export function handleQueryManagersDetails(id) { 
  return request({
    url: 'matchinfomanager/queryManagersDetails',
    method: 'post',
    params:{id:id}
  })
}
