import {
	handlePublicMatchInfo,
	handleMatchInfoDetails,
	handleQueryMatchRegulationsByMatchId
} from '@index/api/mymatchs/publicmatch';

import common from '@index/utils/common';
import commonHeader from '../components/header';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
//quillEditor,
// import {
// 	quillEditor
// } from 'vue-quill-editor'
import tinymce from 'tinymce';

import {
	regionData,
	// CodeToText
} from "element-china-area-data";
export default {
	name: 'publicmatch',
	components: {
		commonHeader,
		tinymce
	},
	data() {
		return {
			havAuth: true,
			initParams: {
				selector: '#tinymce',
				language_url: '/static/tinymce/langs/zh_CN.js',
				language: 'zh_CN',
				height: 500,
				plugins: 'link lists image code table colorpicker wordcount contextmenu formatpainter code lineheight',
				toolbar: 'undo redo| code lineheight | bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | link unlink image code | removeformat',
				branding: false,
				powerpaste_word_import: "merge", // 是否保留word粘贴样式  clean | merge 
				powerpaste_html_import: 'merge', // propmt, merge, clean
				content_style: `p{  margin: 0px;}`,
				images_upload_url: process.env.VUE_APP_PROXY_URL + 'sys/sysResource/out/uploadImg2',
				imagetools_cors_hosts: ['*.dance5.top', 'bm.dance5.top', 'dance5.top', 'www.dance5.top'],
				powerpaste_allow_local_images: true, //允许带图片
				paste_data_images: true,
				images_upload_credentials: true,
				readonly: this.havAuth,
				port: '80',
			},
			form: {
				beginTime: '',
				endTime: ''
			},
			editorOption: {
				placeholder: ''
			},
			formRules: {
				matchName: [{
					required: true,
					message: '比赛名称不能为空!',
					trigger: 'blur'
				}],
				beginTime: [{
					required: true,
					message: '比赛开始日期不能为空!',
					trigger: 'blur'
				}],
				endTime: [{
					required: true,
					message: '比赛结束日期不能为空!',
					trigger: 'blur'
				}]
			},
			options: regionData,
			selectedOptions: [],
			content: '',

		};
	},
	watch: {

	},
	created() {
		// if (this.$route.query.matchId) { //获取详情
		// 	this.matchInfoDetails(this.$route.query.matchId)
		// }
		//this.init={...this.init,r: Math.random()};

	},
	mounted() {
		this.tinymceInit();
	},
	destroyed() {
		tinymce.remove();
	},


	methods: {
		tinymceInit() {
			let _this = this;

			//var address=location.href.substring(0,location.href.indexOf("dance5.top"))+"/sys/sysResource/out/uploadImg2";
			var requestAddress = "";
			var protocol = "";
			var hrefStr = location.href.toLowerCase(); // location.href;	https://jfzsoft.com/mobile/login			
			var sub = "";
			protocol = hrefStr.substring(0, hrefStr.indexOf("//")) + "//";
			hrefStr = hrefStr.replace(/https:\/\/|http:\/\/|www./g, '');
			sub = hrefStr.substring(0, hrefStr.indexOf('.'));
			if (sub&&sub!='dance5') {
				requestAddress = protocol + sub + ".dance5.top/api/score/sys/sysResource/out/uploadImg2";
				this.initParams.images_upload_url = requestAddress;
			}
			//tinymce.execCommand('mceAddControl',true,'#tinymce'); 
			console.log(">>requestAddress>>>>" + requestAddress);
			tinymce.init({
				...this.initParams,
				init_instance_callback: function () {
					if (_this.$route.query.matchId) { //获取详情
						_this.matchInfoDetails(_this.$route.query.matchId)
					} else {
						window.tinymce.get('tinymce').setContent(_this.content);
					}
				}
			});
		},
		goback() { //返回	

			//this.$router.go(-1);
			this.$router.push({
				path: '/hosterMatchInfo',
				query: {
					menusShow: true
				}
			})

		},
		handleChange() {
			// let loc = "";
			// for (let i = 0; i < this.selectedOptions.length; i++) {
			//   loc += CodeToText[this.selectedOptions[i]];
			// }

		},
		submit() {

			try { //验证
				common.requiredValidate([{
					value: this.form.matchName,
					tips: "比赛名称不能为空!"
				}, {
					value: this.form.beginTime,
					tips: "请选择比赛开始日期!"
				}, {
					value: this.form.endTime,
					tips: "请选择比赛截止日期!"
				}]);
			} catch (e) {
				this.$message({
					type: 'error',
					message: e
				});
				return;
			}
			this.form.regulations = window.tinymce.get('tinymce').getContent();
			handlePublicMatchInfo(this.form).then(res => {
				if (res.status == 200) {
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.$router.push({
						path: '/mymatchs'
					})
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}

			});
		},
		matchInfoDetails(id) {
			if (!id) {
				return;
			}
			handleMatchInfoDetails(id).then(res => {
				if (res.status == 200) {
					this.form = res.data;
					//	var user = JSON.parse(sessionStorage.getItem('user'));
					//	this.havAuth = (user.id == res.data.createUserId); //是否具有修改权限
					this.havAuth = res.data.state == '3' ? false : true; //结束的比赛不能再修改
					this.queryMatchRegulationsByMatchId(this.form.id);
				}
			});
		},
		editorInit() {

		},
		queryMatchRegulationsByMatchId(matchId) { //获取赛事规程
			handleQueryMatchRegulationsByMatchId(matchId).then(res => {
				if (res.status == 200) {
					window.tinymce.get('tinymce').setContent(res.data.regulations);
				}
			});
		}

	}
}