<template>
	<div class="menu-header">
		<el-row>
			<el-col :span="menusShow?8:23">
				<div style="display: inline-block;overflow: hidden;">
					<el-link type="primary" :underline="false" @click="toIndexPage">
						<span class="logo">
							{{ domainInfo ? domainInfo.schemeName : "我是记分长" }}
						</span>
					</el-link>
					<span class="logo-tips">文化艺术体育竞技展演报名系统</span>
				</div>
			</el-col>
			<el-col v-if="menusShow" :span="15" style="text-align: -webkit-right;">
				<el-menu v-if="menusShow" :default-active="$route.path" mode="horizontal" background-color="#1e4683"
					style="display: flex;" text-color="#fff" @select="handleMenusSelect" :router="true">
					<el-menu-item index="/hosterMatchInfo"
						:route="{path:'/hosterMatchInfo',query:{menusShow:true}}">比赛详情
					</el-menu-item>
					<el-menu-item :disabled=" !matchInfoManager||matchInfoManager.matchBackSetAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.matchBackSetAuthFlag!='1'?'noauth-forbid':''"
						index="/matchinfo" :route="{path:'/matchinfo',query:{menusShow:true}}">后台设置
					</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.onlineAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.onlineAuthFlag!='1'?'noauth-forbid':''"
						index="/onlinesignuptable" :route="{path:'/onlinesignuptable',query:{menusShow:true}}">在线报名表
					</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.formAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.formAuthFlag!='1'?'noauth-forbid':''"
						index="/formsignuptable" :route="{path:'/formsignuptable',query:{menusShow:true}}">
						正式报名表</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.arrangeAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.arrangeAuthFlag!='1'?'noauth-forbid':''"
						index="/matcharrangtable" :route="{path:'/matcharrangtable',query:{menusShow:true}}">
						赛事安排表
					</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.summaryAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.summaryAuthFlag!='1'?'noauth-forbid':''"
						index="/matchsummarytable"
						:route="{path:'/matchsummarytable',query:{menusShow:true}}">赛程总表</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.detailAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.detailAuthFlag!='1'?'noauth-forbid':''"
						index="/matchdetailstable"
						:route="{path:'/matchdetailstable',query:{menusShow:true}}">选手赛程表</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.scoreAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.scoreAuthFlag!='1'?'noauth-forbid':''"
						index="/scoretable" :route="{path:'/scoretable',query:{menusShow:true}}">成绩表
					</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.teamManageAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.teamManageAuthFlag!='1'?'noauth-forbid':''"
						index="/teammanage" :route="{path:'/teammanage',query:{menusShow:true}}">代表队管理
					</el-menu-item>
					<el-menu-item :disabled="!matchInfoManager||matchInfoManager.groupsettingAuthFlag!='1'"
						:class="!matchInfoManager||matchInfoManager.groupsettingAuthFlag!='1'?'noauth-forbid':''"
						index="/groupsetitem" :route="{path:'/groupsetitem',query:{menusShow:true}}">组别设项
					</el-menu-item>
				</el-menu>

			</el-col>
			<el-col :span="1" style="text-align: center;">
				<el-dropdown @command="headderCommand">
					<img class="settingImg ant-dropdown-trigger" data-src="static/set_icon.png"
						src="static/set_icon.png" lazy="loaded">
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
							<el-dropdown-item command="out">退出</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</el-col>
		</el-row>
		<modifypassword ref="modifypassword"> </modifypassword>
	</div>
</template>

<script>
	import modifypassword from '@index/components/modifypassword.vue';
	import common from '@index/utils/common';
	import {

		Message
	} from 'element-ui'

	import {
		handleQueryUserPublicMatchAuth
	} from '@index/api/team/teamindex'

	import {
		handleQueryMatchManagerAuth
	} from '@index/api/mymatchs/matchinfo'

	export default {
		name: 'header-matchs',
		components: {
			modifypassword
		},
		data() {
			return {
				menusShow: false,
				domainInfo: null,
				matchId: sessionStorage.getItem('currMatchId'),
				matchInfoManager: {
					matchBackSetAuthFlag: '0'
				},
			};
		},
		watch: {		
		},
		created() {			
			// window.addEventListener('storage', this.afterQRScan)	
			
				

		},
		mounted() {			
			this.handleDomain();
			this.intiTitle();
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			intiTitle() {
				var temp = sessionStorage.getItem('domainInfo');
				this.domainInfo = temp ? JSON.parse(temp) : {
					schemeName: '我是记分长'
				};
				if (this.domainInfo) {
					document.title = this.domainInfo.schemeName;
				}
			},
			headderCommand(command) {
				switch (command) {
					case "modifyPassword":
						this.$refs.modifypassword.openDialog();
						break;
					case "out":
						common.signout();
						break;
				}
			},
			init(menusShow) {				
				this.$data.menusShow = menusShow;
				this.queryMatchManagerAuth();

			},
			toIndexPage() {
				handleQueryUserPublicMatchAuth().then(res => {
					if (res.status == 200) {
						if (res.data.authStatus == '1' || res.data.authStatus == '2') { //允许发布赛事（授权比赛）
							this.$router.push({
								path: '/transitionpage'
							})
						} else { //不允许发布赛事
							this.$router.push({
								path: '/mymatchs'
							})
						}

					} else {
						Message({
							type: "error",
							message: res.msg,
							duration: 5000
						});
					}
				});
			},

			handleMenusSelect(key, keyPath) {
				
			},
			// 处理头部函数
			handleDomain() {
				const domainInfo = sessionStorage.getItem('domainInfo');
				if (domainInfo) {
					this.domainInfo = JSON.parse(domainInfo);
				}
			},
			queryMatchManagerAuth() {
				var matchId=sessionStorage.getItem('currMatchId');				
				if (!matchId) {
					return;
				}
				handleQueryMatchManagerAuth(matchId).then(res => {
					this.$data.matchInfoManager = res.data;
				})
			}


		}
	}
</script>

<style lang="scss">
	.menu-header {
		background: rgb(30, 70, 131);
		height: 3.5rem;
		line-height: 3.5rem;
		display: inline-block;
		width: 100%;
	}

	.noauth-forbid {
		background-color: #7F7F7F !important;
		text-decoration: line-through;
		cursor: no-drop;
	}


	.el-menu.el-menu--horizontal {
		border-bottom: none;
		float: right;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none !important;
		color: white !important;
		background: #F32C6C !important;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 3.5rem;
		line-height: 3.5rem;
	}

	.el-dropdown {
		// width: 22px;
		// height: 22px;
		-o-object-fit: fill;
		object-fit: fill;
		// float: right;
		cursor: pointer;
		margin-top: 14px;
	}

	.settingImg {
		width: 22px;
		height: 22px;
		-o-object-fit: fill;
		object-fit: fill;
		float: right;
	}



	.logo-tips {
		padding-left: 10px;
		font-size: 0.7rem !important;
		color: #D7D7D7;
	}

	.el-menu-item {
		font-size: 0.9rem !important;
		// font-weight: bolder !important;		

	}

	.el-menu-item:hover {
		background-color: #F32C6C !important;
	}
</style>
<style lang="scss" scoped>
	.logo {
		font-size: 1.6rem !important;
		color: white;

		padding-left: 1.5rem;
		font-weight: bolder;
	}
</style>